import './App.css';

function App() {
  return (
    <div className="App">
      <div class="content">
        <img src='resources/Under construction-bro.svg' className='logo'></img>
        <h2>FinoTech.ca</h2>
        <h1>Web Design and Digital Marketing</h1>
        <h3>Site Under Maintenance</h3>
        <p>We are currently updating our website. We apologize for any inconvenience this may cause. Please check back later.</p>

        <div class="contact-info">
          <a href="tel:+12137720805"><i className='fa fa-phone'></i>+1 (213) 772-0805</a>
          <a href="mailto:info@finotech.ca"><i className='fa fa-envelope'></i> info@finotech.ca</a>
          <a href=''> <i className="fa fa-map-marker"></i>Toronto, Ontario</a>
        </div>
      </div>

    </div>
  );
}

export default App;
